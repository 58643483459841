import React, {Fragment} from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import fetch from 'node-fetch';
import {useQuery, useMutation} from '@apollo/client';
import { menuPageQuery } from '../../graphql/queries'
import {updateVerifySetupMutation} from '../../graphql/mutations'
import './setup.css';
let blocked = false;
export function SetupPage(props) {
  
  const { loading, error, data } = useQuery(menuPageQuery)
  //const [{ data, loading, error }, refetch] = useAxios('https://api-fusiondiscordbot.glitch.me/api/auth')
  const [verified, setVerified] = React.useState(false);
  const [updateVerifySetup] = useMutation(updateVerifySetupMutation)
  const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';   

  async function verifyCallback (token, ekey) {
    if (!token || !ekey) return
    blocked = true;
    document.getElementsByClassName('SetupPage')[0].style = 'cursor: progress;';
    document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';

    let res = await fetch('https://corsproxy.io/?https://hcaptcha.com/siteverify', {
      method: 'post',
      body: `response=${token}&secret=${process.env.REACT_APP_hcaptchaSecret}`,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
    
    res = await res.json()
	console.log(res)
    setVerified(res['success'])
  blocked = false;
  document.getElementsByClassName('SetupPage')[0].style = 'cursor: default;';
  document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
  }

  const handleConfirmation = async () => {
    if (blocked == true) return
    if (verified) {
      if (window.localStorage.registeredAccount) {
        blocked =true;
        document.getElementsByClassName('confirm-save')[0].innerHTML = 'Você já possui uma conta registrada no Fusion.',
        document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
        document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';

        await updateVerifySetup({
          variables: {
			failed: true,
          }
        })
      
      setTimeout(function () {
        blocked = false;
        document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
        document.getElementsByClassName('confirm-save')[0].innerHTML = 'Registrado com sucesso!',
      document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
      }, 3000)
      } else {
        let res = null;
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        
        console.log(response);
        res = response.data;
      } catch(e) {
        blocked = true;
        document.getElementsByClassName('confirm-save')[0].innerHTML = 'Algum erro ocorreu. Algum bloqueador de anúncios pode estar causando o problema.'
        document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
        document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';

        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('confirm-save')[0].innerHTML = 'Registrado com sucesso!',
        document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
      }
      
      blocked = true;
      document.getElementsByClassName('SetupPage')[0].style = 'cursor: progress;';
      document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';

	const verifyVpn = await axios.get(`https://api-fusiondiscordbot.glitch.me/api/ipinfo?ip=${res.ip}`)
	//const verifyVpn = await fetch(`https://pacific-mountain-18286.herokuapp.com/https://ipqualityscore.com/api/json/ip/${process.env.REACT_APP_ipqualityscoreKey}/${res.ip}`)
  console.log(verifyVpn)
	let verifyVpnRes = await verifyVpn.data
	console.log(verifyVpnRes)

	if (verifyVpnRes.score > 50) {
		document.getElementsByClassName('confirm-save')[0].innerHTML = 'Por favor, desative sua VPN ou Proxy para verificar sua conta no Fusion.',
        	document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;', await updateVerifySetup({
          variables: {
            clientIp: res.ip,
			failed: true,
			ipScore: parseInt(verifyVpnRes.score)
          }
        })

		setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('confirm-save')[0].innerHTML = 'Registrado com sucesso!',
        document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
  } else {

        let result = await updateVerifySetup({
          variables: {
            clientIp: res.ip,
			failed: false
          }
        })

        console.log(result.data.updateVerifySetup)
          if (result.data.updateVerifySetup.error == 1) {
            blocked = true;
            document.getElementsByClassName('confirm-save')[0].innerHTML = 'Você já possui uma conta registrada no Fusion.',
          document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
          document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';
          }
          else if (result.data.updateVerifySetup.error == 2) {
            blocked = true;
            document.getElementsByClassName('confirm-save')[0].innerHTML = 'Ops! Algum erro ocorreu :(',
          document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
          document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';
          }
        
        else if (result.data.updateVerifySetup.error == 0) {
          window.localStorage.registeredAccount = data.getUser.id;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: visible;'
          document.getElementsByClassName('SetupPage')[0].style = 'cursor: default;';
        }
        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('confirm-save')[0].innerHTML = 'Registrado com sucesso!',
        document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
	}
      }
    }
    else {
      blocked = true;
      document.getElementsByClassName('SetupPage')[0].style = 'cursor: progress;';
      document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';
      document.getElementsByClassName('confirm-save')[0].innerHTML = 'Você precisa confirmar o captcha primeiro',
          document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
        
        document.getElementsByClassName('SetupPage')[0].style = 'cursor: default;';
        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('confirm-save')[0].innerHTML = 'Registrado com sucesso!',
        document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
    }
  }

  if (error) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=setup'
    if (!loading && !error) {
      if (!data || !data.getUser) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=setup'
      console.log(data)
      return (
        <Fragment>
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
</style>
        <body class="SetupPage">
        <nav class="navigation-bar fixed">
<div class="left-side-entries">
<a href="https://fusiondiscordbot.vercel.app" style={{fontFamily: 'Pacifico', paddingTop:1}}>Fusion</a>
  <a href="https://fusiondiscordbot.vercel.app/dashboard" id="dashboard" style={{paddingTop:3}}><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fgear.png?v=1595294274017" height={18} width={18} id="gear"></img> Dashboard</a>
  <a href="https://discord.gg/ryfTBmU" style={{paddingTop:4}}><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fdiscord.png?v=1595296304827" height={18} width={18} id="discord"></img> Suporte</a>
  {/*<a href="https://twitter.com/fusion_discord"><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Ftwitter.png?v=1599094463069" height={18} width={18} id="twitter"></img> Twitter</a>*/}
</div>
<div class="right-side-entries">
{!loading && data && data.getUser ? <div><img src={`https://cdn.discordapp.com/avatars/${data.getUser.id}/${data.getUser.avatar}.png`} height={32} width={32}/><text class="login" style={{bottom: 6, position: 'relative'}}>{data.getUser.username}#{data.getUser.discriminator}</text></div> : <text onClick={login} style={{paddingTop:4}} class="login">Login</text>}
</div>
</nav>
        <div class="main">
          <text class="config-title">Antes de poder se registrar no Fusion</text>
          <text class="text" style={{fontSize:14}}>Confirme que você não é um robô completando o Captcha</text>
          <HCaptcha
        sitekey="25929a13-ed63-494d-96a7-984ce8c2d9a4"
        onVerify={(token,ekey) => verifyCallback(token, ekey)}
      />
  <button type="button" onClick={handleConfirmation} class="confirmButton" id="button">Confirmar</button>
  <text class='confirm-save' style={{visibility: 'hidden', color: 'lightgreen'}}>Registrado com sucesso!</text>
        </div>
        </body>
        </Fragment>
      )
    } return <div id="loading"/>
  
    // xhr.send();
    // }, [])
    // return !loading && (
    //   <div>
    //     <h1>Dashboard Page</h1>
    //     <MenuComponent guilds={guilds} />
    //   </div>
    // )
}