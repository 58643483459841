import axios from 'axios';
const stripe = require('stripe')('sk_test_51P3iMhP4mwauRoaqVip4FZUMrRRLYTFSd3Sp8Rc3AtHoC7qgr45Aq2xT126jhgtsYIuyUUVEsgNO37K2sK0VPP9U006DU34S8I');
export function getUserDetails() {
    return axios({
      method: 'get',
      url: 'https://api-fusiondiscordbot.glitch.me/api/auth',
      withCrdentials: true
  })
};
export function getGuilds(token) {
  return axios.get(`https://api-fusiondiscordbot.glitch.me/api/discord/guilds?token=${token}`, {
    withCredentials: true
  });
}
export function getGuildConfig(guildId, token) {
    return axios.get(`https://api-fusiondiscordbot.glitch.me/api/discord/guilds/${guildId}/config?token=${token}`)
}
export function updateGuildPrefix(guildId, prefix, token) {
  return axios.put(`https://api-fusiondiscordbot.glitch.me/api/discord/guilds/${guildId}/prefix?token=${token}`, {prefix})
}
export function updateDefaultRole(guildId, defaultRole, token) {
  return axios.put(
    `https://api-fusiondiscordbot.glitch.me/api/discord/guilds/${guildId}/roles/default?token=${token}`, {
      defaultRole
    }
  )
}
export function getGuildRoles(guildId, token) {
  return axios.get(`https://api-fusiondiscordbot.glitch.me/api/discord/guilds/${guildId}/roles?token=${token}`)
}

export async function createCheckout() {
  console.log("ena")
    const session = await stripe.checkout.sessions.create({
      line_items: [
        {
          // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
          price: 'price_1P3jdcP4mwauRoaqJuh6wZE7',
          quantity: 1,
        },
      ],
      mode: 'payment',
      success_url: `https://fusiondiscordbot.vercel.app?success=true`,
      cancel_url: `https://fusiondiscordbot.vercel.app?canceled=true`,
    });
  
    console.log(session.url)
    res.redirect(303, session.url);
}
export function getTransactionInfo(transactionCode) {
  if (!transactionCode) return
  /*axios({
    method: 'GET',
    url: `https://ws.pagseguro.uol.com.br/v2/transactions/${transactionCode}?email=${process.env.REACT_APP_pagseguroEmail}&token=${process.env.REACT_APP_pagseguroToken}`,
    headers: {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    }
  })*/
    
}